export const flavorReducer = (state = [], action) => {
    switch (action.type) {
      case 'ALL_FLAVORS':
        return {updatedAt: Date.now(), flavors: action.flavors.flavors};
      case 'ADD_FLAVOR':
        return {updatedAt: Date.now(), flavors: [...state.flavors, action.flavor.data]};
      case 'REMOVE_FLAVOR':
        return {updatedAt: Date.now(), flavors: state.flavors.filter(flavor => flavor._id !== action.flavor)};
      case 'EDIT_FLAVOR':
        let flavors = state.flavors;
        for (var i in flavors) {
          if (flavors[i]._id === action.flavor.flavor._id) {
             flavors[i] = action.flavor.flavor;
             break;
          }
        }
        return {updatedAt: Date.now(), flavors };
      default:
        return state;
    }
}