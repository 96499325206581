// Breweries
export const allBreweries = (breweries) => ({
    type: 'ALL_BREWERIES',
    breweries
})

export const addBrewery = (brewery) => ({
    type: 'ADD_BREWERY',
    brewery
})

export const editBrewery = (brewery) => ({
    type: 'EDIT_BREWERY',
    brewery
})

export const removeBrewery = (brewery) => ({
    type: 'REMOVE_BREWERY',
    brewery
})

// Beers
export const allBeers = (beers) => ({
    type: 'ALL_BEERS',
    beers
})

export const addBeer = (beer) => ({
    type: 'ADD_BEER',
    beer
})

export const editBeer = (beer) => ({
    type: 'EDIT_BEER',
    beer
})

export const removeBeer = (beer) => ({
    type: 'REMOVE_BEER',
    beer
})

// Locations
export const allLocations = (locations) => ({
    type: 'ALL_LOCATIONS',
    locations
})

export const addLocation = (location) => ({
    type: 'ADD_LOCATION',
    location
})

export const removeLocation = (location) => ({
    type: 'REMOVE_LOCATION',
    location
})

export const editLocation = (location) => ({
    type: 'EDIT_LOCATION',
    location
})

// Flavors
export const allFlavors = (flavors) => ({
    type: 'ALL_FLAVORS',
    flavors
})

export const addFlavor = (flavor) => ({
    type: 'ADD_FLAVOR',
    flavor
})

export const removeFlavor = (flavor) => ({
    type: 'REMOVE_FLAVOR',
    flavor
})

export const editFlavor = (flavor) => ({
    type: 'EDIT_FLAVOR',
    flavor
})

// Styles
export const allStyles = (styles) => ({
    type: 'ALL_STYLES',
    styles
})

export const addStyle = (style) => ({
    type: 'ADD_STYLE',
    style
})

export const removeStyle = (style) => ({
    type: 'REMOVE_STYLE',
    style
})

export const editStyle = (style) => ({
    type: 'EDIT_STYLE',
    style
})

// Users

export const login = (user) => ({
    type: 'LOGIN',
    user
})

export const logout = (bool) => ({
    type: 'LOGOUT',
    bool
})

export const allUsers = (users) => ({
    type: 'ALL_USERS',
    users
})

export const addUser = (user) => ({
    type: 'ADD_USER',
    user
})

export const removeUser = (user) => ({
    type: 'REMOVE_USER',
    user
})

export const editUser = (user) => ({
    type: 'EDIT_USER',
    user
})