export const styleReducer = (state = [], action) => {
  switch (action.type) {
    case 'ALL_STYLES':
      return { updatedAt: Date.now(), styles: action.styles.styles };
    case 'ADD_STYLE':
      return { updatedAt: Date.now(), styles: [...state.styles, action.style.data] };
    case 'REMOVE_STYLE':
      return { updatedAt: Date.now(), styles: state.styles.filter(style => style._id !== action.style) };
    case 'EDIT_STYLE':
      let styles = state.styles;
      for (var i in styles) {
        if (styles[i]._id === action.style.style._id) {
          styles[i] = action.style.style;
          break;
        }
      }
      return { updatedAt: Date.now(), styles };
    default:
      return state;
  }
}