import { combineReducers } from 'redux';
import { breweryReducer } from './breweryReducer';
import { beerReducer } from './beerReducer';
import { locationReducer } from './locationReducer';
import { flavorReducer } from './flavorReducer';
import { styleReducer } from './styleReducer';
import { userReducer } from './userReducer';
import { usersReducer } from './usersReducer';

const rootReducer = combineReducers({
  breweries: breweryReducer,
  beers: beerReducer,
  locations: locationReducer,
  styles: styleReducer,
  flavors: flavorReducer,
  user: userReducer,
  users: usersReducer
});

export default rootReducer;