export const beerReducer = (state = [], action) => {
    switch (action.type) {
      case 'ALL_BEERS':
        return { updatedAt: Date.now(), beers: action.beers.beers};
      case 'ADD_BEER':
        return { updatedAt: Date.now(), beers: [...state.beers, action.beer.data] }
      case 'REMOVE_BEER':
        return {updatedAt: Date.now(), beers: state.beers.filter(beer => beer._id !== action.beer._id)};
      case 'EDIT_BEER':
        let beers = state.beers;
        for (var i in beers) {
          if (beers[i]._id === action.beer._id) {
            beers[i] = action.beer;
            break; //Stop this loop, we found it!
          }
        }
        return {updatedAt: Date.now(), beers };
      default:
        return state;
    }
}