export const locationReducer = (state = [], action) => {
    switch (action.type) {
      case 'ALL_LOCATIONS':
        return { updatedAt: Date.now(), locations: action.locations.locations};
      case 'ADD_LOCATION':
        return { updatedAt: Date.now(), locations: [...state.locations, action.location.data] }
      case 'REMOVE_LOCATION':
        return { updatedAt: Date.now(), locations: state.locations.filter(location => location._id !== action.location._id)};
      case 'EDIT_LOCATION':
        let locations = state.locations;
        for (var i in locations) {
          if (locations[i]._id === action.location._id) {
            locations[i] = action.location;
            break;
          }
        }
        return {updatedAt: Date.now(), locations };
      default:
        return state;
    }
}