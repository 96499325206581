export const breweryReducer = (state = [], action) => {
    switch (action.type) {
      case 'ALL_BREWERIES':
        return {updatedAt: Date.now(), breweries: action.breweries.breweries};
      case 'ADD_BREWERY':
        return {updatedAt: Date.now(), breweries: [...state.breweries, action.brewery.data]};
      case 'REMOVE_BREWERY':
        return {updatedAt: Date.now(), breweries: state.breweries.filter(brewery => brewery._id !== action.brewery._id)};
      case 'EDIT_BREWERY':
        let breweries = state.breweries;
        for (var i in breweries) {
          if (breweries[i]._id === action.brewery.brewery._id) {
             breweries[i] = action.brewery.brewery;
             break;
          }
        }
        return {updatedAt: Date.now(), breweries };
      default:
        return state;
    }
}