export const usersReducer = (state = {}, action) => {
    switch (action.type) {
      case 'ALL_USERS':
        return {updatedAt: Date.now(), users: action.users.users};
      case 'ADD_USER':
        return {updatedAt: Date.now(), users: [...state.users, action.user.user]};
      case 'REMOVE_USER':
        return {updatedAt: Date.now(), users: state.users.filter(user => user._id !== action.user)};
      case 'EDIT_USER':
        let users = state.users;
        // console.log(action.user)
        for (var i in users) {
          if (users[i]._id === action.user._id) {
              users[i] = action.user;
              break;
          }
        }
        return {updatedAt: Date.now(), users };
      default:
        return state;
    }
}